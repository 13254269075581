import { SelectOptionInterface } from '../../../app-shared-elements/_interfaces/select-option.interface';
import { Device } from '../../../app-shared-elements/_interfaces/Device';
import { CopyListInterface } from '../../_interfaces/registrator-copy.interface';

export class CopyConfiguration {
    static readonly type = '[CopyConfiguration] CopyConfiguration';

    constructor() {}
}

export class GetDeviceOptions {
    static readonly type = '[CopyConfiguration] GetDeviceOptions';

    constructor() {}
}

export class SetFirstSelected {
    static readonly type = '[CopyConfiguration] SetFirstSelected';

    constructor(public device: SelectOptionInterface<string, string, Device>) {}
}

export class SetSecondSelected {
    static readonly type = '[CopyConfiguration] SetSecondSelected';

    constructor(public device: SelectOptionInterface<string, string, Device>) {}
}

export class ChangeList {
    static readonly type = '[CopyConfiguration] ChangeList';

    constructor(public changeList: CopyListInterface) {}
}

export class ClearCopyConfigurationState {
    static readonly type = '[CopyConfiguration] ClearCopyConfigurationState';
}
