import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {CopyConfigurationRepsponseInterface} from '../_interfaces/copy-configuration-response.interface';
import {Device} from '../../app-shared-elements/_interfaces/Device';

@Injectable({
    providedIn: 'root',
})
export class CopyConfigurationService {

    constructor(
        private translateService: TranslateService
    ) {
    }

    parseDeviceOptionName(registrator: Device): string {
        let name = registrator.name ?? registrator.defaultName;

        if (registrator.name !== registrator.defaultName) {
            name += ' (' + registrator.defaultName + ')';
        }

        return name;
    }

    async getCopyConfigurationResponseMessage(data: CopyConfigurationRepsponseInterface): Promise<string> {
        if (!data) {
            return '';
        }

        try {
            let message = '';

            for (const key of Object.keys(data)) {
                message += `${await this.translateService.get(`copyConfiguration.response.${key}`, {count: data[key]}).toPromise()} </br>`;
            }

            return message;
        } catch (e) {
            console.log(e);
            return '';
        }
    }
}
