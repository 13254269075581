import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { SelectOptionInterface } from '../../../app-shared-elements/_interfaces/select-option.interface';
import { Device } from '../../../app-shared-elements/_interfaces/Device';
import { DevicesState } from '../../../device-dashboard/_store/states/user-devices.state';
import {
    ChangeList,
    ClearCopyConfigurationState,
    CopyConfiguration,
    GetDeviceOptions,
    SetFirstSelected,
    SetSecondSelected,
} from '../actions/copy-configuration.actions';
import { CreationType } from '../../../app-shared-elements/_enums/registrator-sync-status.enu';
import { ApiResponse } from '../../../app-shared-elements/_interfaces/ApiRequest';
import { HttpClient } from '@angular/common/http';
import { CopyListInterface, RegistratorCopyInterface } from '../../_interfaces/registrator-copy.interface';
import { HTTP_STATUS } from '../../../app-shared-elements/_enums/status.enum';
import { RegistratorCopyTypeEnum } from '../../_enums/registrator-copy-type.enum';
import { CopyConfigurationListInterface } from '../../_interfaces/copy-configuration-list.interface';
import { NotificationsService } from '../../../app-shared-elements/_services/notifications.service';
import { TooltipStatusEnum } from '../../../app-shared-elements/_enums/tooltip-status.enum';
import { PreloaderService } from '../../../app-shared-elements/_services/preloader.service';
import { CopyConfigurationRepsponseInterface } from '../../_interfaces/copy-configuration-response.interface';
import { CopyConfigurationService } from '../../_services/copy-configuration.service';
import { DeviceTypeEnum } from '../../../app-shared-elements/_enums/device-type.enum';

export interface CopyConfigurationStateModel {
    deviceOptions: SelectOptionInterface<string, string, Device>[];
    currentFirstSelectDevice: SelectOptionInterface<string, string, Device>;
    currentSecondSelectDevice: SelectOptionInterface<string, string, Device>;
    list: CopyConfigurationListInterface[];
    copyList: CopyListInterface;
}

const COPY_CONFIGURATION_TOKEN = new StateToken<CopyConfigurationStateModel>('copyConfiguration');

const initCopyList: CopyConfigurationListInterface[] = [
    {
        title: 'copyConfiguration.list.deviceSettings',
        name: RegistratorCopyTypeEnum.DEVICE_SETTINGS,
    },
    {
        title: 'copyConfiguration.list.variableName',
        name: RegistratorCopyTypeEnum.VARIABLE_NAME,
    },
    {
        title: 'copyConfiguration.list.reports',
        name: RegistratorCopyTypeEnum.REPORTS,
    },
    {
        title: 'copyConfiguration.list.logicEvent',
        name: RegistratorCopyTypeEnum.LOGIC_EVENTS,
    },
    {
        title: 'copyConfiguration.list.variableSettings',
        name: RegistratorCopyTypeEnum.VARIABLE_SETTINGS,
    },
];

const listName: CopyListInterface = {
    device_settings: false,
    reports: false,
    logic_events: false,
    variable_settings: false,
    variable_name: false,
};

@State<CopyConfigurationStateModel>({
    name: COPY_CONFIGURATION_TOKEN,
    defaults: {
        deviceOptions: [],
        currentFirstSelectDevice: null,
        currentSecondSelectDevice: null,
        list: initCopyList,
        copyList: listName,
    },
})
@Injectable()
export class CopyConfigurationState {
    constructor(
        private store: Store,
        private http: HttpClient,
        private notificationService: NotificationsService,
        private preloaderService: PreloaderService,
        private copyConfigurationService: CopyConfigurationService,
    ) {}

    @Selector()
    static getDeviceOptions(state: CopyConfigurationStateModel): SelectOptionInterface<string, string, Device>[] {
        return state.deviceOptions;
    }

    @Selector()
    static getFirstSelectedDevice(state: CopyConfigurationStateModel): SelectOptionInterface<string, string, Device> {
        return state.currentFirstSelectDevice;
    }

    @Selector()
    static getSecondSelectedDevice(state: CopyConfigurationStateModel): SelectOptionInterface<string, string, Device> {
        return state.currentSecondSelectDevice;
    }

    @Selector()
    static getCopyConfigurationList(state: CopyConfigurationStateModel): CopyConfigurationListInterface[] {
        return state.list;
    }

    @Selector()
    static getCopyListName(state: CopyConfigurationStateModel): CopyListInterface {
        return state.copyList;
    }

    @Action(CopyConfiguration)
    async copyConfiguration(ctx: StateContext<CopyConfigurationStateModel>): Promise<void> {
        const state = ctx.getState();

        const data: RegistratorCopyInterface = {
            from: state.currentFirstSelectDevice.key,
            to: state.currentSecondSelectDevice.key,
            ...state.copyList,
        };

        const result: ApiResponse<CopyConfigurationRepsponseInterface> = (await this.http
            .post('/api/registrator-copy', { ...data })
            .toPromise()
            .catch((e) => console.log(e))) as ApiResponse<CopyConfigurationRepsponseInterface>;

        if (result && result.status === HTTP_STATUS.SUCCESS) {
            const message = await this.copyConfigurationService.getCopyConfigurationResponseMessage(result.data);
            this.notificationService.onEmit(TooltipStatusEnum.update, false, message);
            ctx.setState({
                ...state,
            });
        } else {
            this.notificationService.onEmit(TooltipStatusEnum.error, false);
            this.preloaderService.destroyPreloader();
        }
    }

    @Action(GetDeviceOptions)
    getDeviceOptions(ctx: StateContext<CopyConfigurationStateModel>): void {
        const state = ctx.getState();

        const devices = this.store.selectSnapshot(DevicesState.getDevices);
        const deviceOptions = [];

        const registrators = this.store
            .selectSnapshot(DevicesState.getOwnerRegistrators)
            .filter((r) => r.creationType === CreationType.ORIGIN)
            .sort((a, b) => (a.name > b.name ? 1 : -1));

        registrators.forEach((r) => {
            const devicesByRegistrator: Device[] = devices
                .filter((d) => d.registratorId === r.id && (d.type === DeviceTypeEnum.sensor || d.type === DeviceTypeEnum.datalogger))
                .sort((a, b) => (a.type > b.type ? 1 : -1));

            deviceOptions.push({
                key: r.id,
                value: r.name ?? r.defaultName,
                property: r,
                postIconPath: `./assets/design/icons/custom-select/registrator.svg`,
            });

            devicesByRegistrator.forEach((d) => {
                deviceOptions.push({
                    key: d.id,
                    value: d.name ?? d.defaultName,
                    property: d,
                    postIconPath: `./assets/design/icons/custom-select/${d.type}.svg`,
                });
            });
        });

        ctx.setState({
            ...state,
            deviceOptions,
        });
    }

    @Action(SetFirstSelected)
    setFirstSelected(ctx: StateContext<CopyConfigurationStateModel>, payload: SetFirstSelected): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            currentFirstSelectDevice: payload.device,
            currentSecondSelectDevice: null,
        });
    }

    @Action(SetSecondSelected)
    setSecondSelected(ctx: StateContext<CopyConfigurationStateModel>, payload: SetSecondSelected): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            currentSecondSelectDevice: payload.device,
            list:
                payload.device?.property.type === DeviceTypeEnum.sensor || payload.device?.property.type === DeviceTypeEnum.datalogger
                    ? state.list.filter((f) => f.name !== RegistratorCopyTypeEnum.REPORTS)
                    : initCopyList,
        });
    }

    @Action(ChangeList)
    changeList(ctx: StateContext<CopyConfigurationStateModel>, payload: ChangeList): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            copyList: payload.changeList,
        });
    }

    @Action(ClearCopyConfigurationState)
    clearState(ctx: StateContext<CopyConfigurationStateModel>): void {
        const state = ctx.getState();

        ctx.setState({
            ...state,
            currentFirstSelectDevice: null,
            currentSecondSelectDevice: null,
            list: initCopyList,
            copyList: listName,
        });
    }
}
